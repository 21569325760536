<template>
  <div class="course-container">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程目标</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="minBody">
      <div class="cardList" v-loading="leftLoading" element-loading-text="加载中">
        <div class="top">
          <div class="tittle">
            <img src="../../../assets/1.png" height="20" width="20" style="margin-right: 3px" />
            设置目标
          </div>
          <div style="width: 70%;white-space: nowrap;display:flex;align-items: center;margin-right: 12px;justify-content: flex-end">
            <Button type="primary" @click="handleAddTarget">添加</Button>
            <Button type="primary" :disabled="showAdd" @click="delTarget" style="margin: 0 10px">删除</Button>
            <Button type="primary" @click="toTargetChart" title="设置目标">
              <!--              <i class="iconfont icon-zhankaihuabu"></i>-->
              <Icon type="md-expand" :size="20" />
            </Button>
          </div>
        </div>
        <div style="height: calc(100% - 55px);overflow: auto;">
          <div v-if="showAdd" style="border-bottom: 1px solid #eaedf1;text-align: initial">
            <div style="margin-left: 30px">
              <div style="margin: 15px 0">
                <el-input v-model="addTarget" placeholder="请输入目标名称" maxlength="30" show-word-limit style="width: 70%" />
              </div>
              <div class="description-add">
                <el-input v-model="addDescription" maxlength="200" show-word-limit type="textarea" placeholder="请输入目标描述" style="width: 70%" />
                <span slot="suffix" style="display: flex; align-items: center; height: 100%;margin-left: 20px">
                  <i class="el-icon-check" @click="addTargetList"></i>
                  <i class="el-icon-close" style="margin-left: 15px" @click="addCancel"></i>
                </span>
              </div>
            </div>
          </div>
          <draggable @end="moveTarget" style="width: 100%">
            <div
              style="width: 100%"
              v-for="target in targetList"
              :key="target.id"
              class="target"
              @click="getTargetList(target)"
              @mouseenter="targetEnter(target)"
              @mouseleave="showIcon = false"
              :style="targetListId === target.id ? 'background-color: #ECF2FC' : ''"
            >
              <div style="width: 100%">
                <div style="width: 100%;display: flex;justify-content: space-between">
                  <CheckboxGroup v-model="targetVal" @on-change="handleCheck" style="margin-left: 20px">
                    <Checkbox :label="target.id">{{ target.name }}</Checkbox>
                  </CheckboxGroup>
                  <span @click="toModify(target, true)">修改</span>
                </div>
                <div style="width: 100%">
                  <el-tooltip effect="dark" :content="target.description" :disabled="target.description.length < 30" placement="top-start">
                    <div class="description text-ellipsis">{{ target.description }}</div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div class="cardList" style="margin: 0 15px" v-loading="centerLoading" element-loading-text="加载中">
        <div class="top">
          <div class="tittle">
            <img src="../../../assets/2.png" height="20" width="20" style="margin-right: 5px" />
            设置阶段
          </div>
          <div>
            <Button :disabled="targetListId === 0" type="primary" @click="showAddTarget = true">添加</Button>
            <Button type="primary" :disabled="showAddTarget" @click="toDeleteStage" style="margin: 0 12px">删除</Button>
          </div>
        </div>
        <div style="height: calc(100% - 55px);overflow: auto">
          <div v-if="showAddTarget" style="border-bottom: 1px solid #eaedf1;text-align: initial">
            <div style="margin-left: 30px">
              <div style="margin: 15px 0">
                <el-input v-model="addStage" maxlength="30" show-word-limit placeholder="请输入阶段名称" style="width: 70%" />
              </div>
              <div class="description-add">
                <el-input
                  v-model="addStageDescription"
                  maxlength="200"
                  show-word-limit
                  type="textarea"
                  placeholder="请输入阶段描述"
                  style="width: 70%"
                />
                <span slot="suffix" style="display: flex; align-items: center; height: 100%;margin-left: 20px">
                  <i class="el-icon-check" @click="addStageList"></i>
                  <i class="el-icon-close" style="margin-left: 15px" @click="addStageCancel"></i>
                </span>
              </div>
            </div>
          </div>
          <draggable @end="dragStageEnd">
            <div
              v-for="stage in gradation"
              :key="stage.id"
              @click="getStageList(stage)"
              class="target"
              :style="stageListId === stage.id ? 'background-color: #ECF2FC' : ''"
              @mouseenter="stageEnter(stage)"
              @mouseleave="showStageIcon = false"
            >
              <div style="width: 100%">
                <div style="display: flex;justify-content: space-between;width: 100%">
                  <CheckboxGroup v-model="stageVal" @on-change="handleCheckStage" style="margin-left: 20px">
                    <Checkbox :label="stage.id">{{ stage.name }}</Checkbox>
                  </CheckboxGroup>
                  <span @click="toModify(stage, false)">修改</span>
                </div>
                <div style="width: 100%">
                  <el-tooltip effect="dark" :content="stage.description" :disabled="stage.description.length < 30" placement="top-start">
                    <div class="description text-ellipsis">{{ stage.description }}</div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div class="cardList" v-loading="rightLoading" element-loading-text="加载中">
        <div class="top">
          <div class="tittle">
            <img src="../../../assets/3.png" height="20" width="20" style="margin-right: 5px" />
            关联知识点
          </div>
          <div>
            <Button :disabled="stageListId === 0" type="primary" @click="addAcquire">关联知识点</Button>
            <Button :disabled="knowledgeList.length === 0" type="primary" @click="clearKnowledge" style="margin: 0 12px">清空</Button>
          </div>
        </div>
        <div class="knowledgeList">
          <div v-for="knowledge in knowledgeList" :key="knowledge.id">
            <div class="knowledge">
              <div>{{ knowledge.name }}</div>
              <div
                style="display: flex;align-items: center;width: 12px;justify-content: center;margin-left: 5px"
                @mouseenter="showDele(knowledge)"
                @click="handleClose(knowledge)"
                @mouseleave="showDel = false"
              >
                <i class="el-icon-close"></i>
                <!--                <svg-->
                <!--                  v-if="showDel === false || knowledgeId !== knowledge.id"-->
                <!--                  class="icon"-->
                <!--                  aria-hidden="true"-->
                <!--                  style="height: 5px;width: 5px;color: #4579EA;"-->
                <!--                >-->
                <!--                  <use xlink:href="#icon-guanbi1"></use>-->
                <!--                </svg>-->
                <!--                <svg-->
                <!--                  v-if="showDel === true && knowledgeId === knowledge.id"-->
                <!--                  class="icon"-->
                <!--                  aria-hidden="true"-->
                <!--                  style="height: 12px;width: 12px;color: #4579EA;"-->
                <!--                >-->
                <!--                  <use xlink:href="#icon-cha-copy"></use>-->
                <!--                </svg>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="drawer" v-show="acquire">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="selectMenu">
          <el-menu-item index="1">企业</el-menu-item>
          <el-menu-item index="0">平台</el-menu-item>
        </el-menu>
        <i class="el-icon-close close" @click="acquire = false"></i>
        <div style="margin-bottom: 15px;">
          <el-input placeholder="知识点名称搜索" v-model="knownKeyword" />
        </div>
        <div class="list-container">
          <el-tree
            node-key="id"
            ref="modalTree"
            :filter-node-method="filterNode"
            @check="handleCheckChange"
            :default-checked-keys="knowledgePointList"
            :data="knowledgePointTree"
            :props="{ label: 'name' }"
            check-strictly
            show-checkbox
            :loading="knowledgeLoading"
          />
        </div>
        <div class="demo-drawer-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="addKnowledge">
            确定
          </el-button>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="deleteDialog" :title="`删除提示`" @on-ok="delStage">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认删除{{ deleteName }}吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="clearKnowledge" :title="`删除提示`" @on-ok="clearKnowledgePoints">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认清空知识点吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="modifyDialog" :title="`${modifyMode ? '目标' : '阶段'}修改`" @on-ok="modifyDetermine">
      <div style="margin: 0 auto;">
        <div style="margin-bottom: 20px;text-align: center">
          名称：
          <el-input v-model="modifyData.name" maxlength="30" show-word-limit style="width: 300px" />
        </div>
        <div style="text-align: center">
          描述：
          <el-input v-model="modifyData.description" maxlength="200" show-word-limit style="width: 300px" />
        </div>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import courseApi from '@api/course'
import draggable from 'vuedraggable'
import DeleteModal from '@components/common/DeleteModal'
import knowledgeApi from '@api/knowledge'
import ConfirmDialog from '../../common/dialog/ConfirmDialog'
import YTIcon from '../../common/YTIcon'
export default {
  name: 'targetManagement',
  components: { ConfirmDialog, draggable, DeleteModal, YTIcon },
  data() {
    return {
      activeIndex: '1',
      modifyMode: null,
      modifyData: {
        id: null,
        description: '',
        name: ''
      },
      clearKnowledge: false,
      delStageList: [],
      deleTarget: false,
      delKnge: false,
      showStageIcon: false,
      stageEnterId: 0,
      showIcon: false,
      showDetail: false,
      showStageDetail: false,
      knowledgePointList: [],
      acquire: false,
      addStageDescription: '',
      knownKeyword: '',
      addStage: '',
      showAddTarget: false,
      leftLoading: true, //目标
      centerLoading: true, //阶段
      rightLoading: true, //知识点
      knowledgeLoading: false, //关联知识点
      deleteName: '',
      deleteStage: false,
      knowledgeId: 0,
      showDel: false,
      isAdd: false,
      addTarget: '',
      addDescription: '',
      showAdd: false,
      targetList: [],
      targetVal: [],
      stageVal: [],
      delTargetList: [],
      deleteTarget: false,
      targetListId: 0,
      stageListId: 0,
      gradation: [],
      knowledgeList: [],
      knowledgePointId: [],
      knowledgePointTree: [],
      knowledgeIds: [],
      targetEnterId: 0,
      delStages: false,
      sourceGradationId: 0,
      targetGradationId: 0,
      sourceTargetId: 0,
      objectTargetId: 0,
      gradationId: 0,
      addTargetLoading: false, //添加目标loading
      addStageLoading: false //添加阶段loading
    }
  },
  watch: {
    knownKeyword(type) {
      this.$refs.modalTree.filter(type)
    }
  },
  created() {
    this.getTarget()
  },
  methods: {
    toModify(data, mode) {
      this.modifyMode = mode
      this.modifyData = this.$deepCopy(data)
      this.$refs['modifyDialog'].open()
    },
    modifyDetermine() {
      if (this.modifyData.name === '') {
        this.$message.error('请输入名称')
        return false
      }
      if (this.modifyData.name.length > 30) {
        this.$message.error('名称长度不能超过30个字符')
        return false
      }
      if (this.modifyData.description.length > 200) {
        this.$message.error('描述长度不能超过200个字符')
        return false
      }
      if (this.modifyMode) {
        courseApi.newTargetModify(this.modifyData, this.modifyData.id).then(res => {
          if (res.code === 0) {
            this.$message.success('修改该目标信息成功')
            this.targetListId = this.modifyData.id
            this.getTarget()
          }
        })
      } else {
        courseApi.newTargetGradationModify(this.modifyData, this.modifyData.id).then(res => {
          if (res.code === 0) {
            this.$message.success('修改该阶段信息成功')
            this.getTarget()
          }
        })
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    moveTarget(data) {
      this.targetList.map((item, index) => {
        if (data.oldIndex === index) {
          this.sourceTargetId = item.id
        }
        if (data.newIndex === index) {
          this.objectTargetId = item.id
        }
      })
      let payload = {
        sourceTargetId: this.sourceTargetId,
        objectTargetId: this.objectTargetId
      }
      courseApi.targetSwitch(payload).then(res => {
        this.getTarget()
      })
    },
    clearKnowledgePoints() {
      courseApi.clearGradationKnowledge(this.stageListId).then(res => {
        this.$message.success('知识点已清空')
        this.getKnowledge()
        this.clearKnowledge = false
      })
    },
    handleCheckStage(data) {
      this.delStageList = data
      this.delStages = true
    },
    dragStageEnd(data) {
      this.gradation.map((item, index) => {
        if (data.oldIndex === index) {
          this.sourceGradationId = item.id
        }
        if (data.newIndex === index) {
          this.targetGradationId = item.id
        }
      })
      this.switchStage()
    },
    switchStage() {
      let payload = {
        sourceGradationId: this.sourceGradationId,
        targetGradationId: this.targetGradationId,
        targetId: this.targetListId
      }
      courseApi.switchGradation(payload).then(res => {
        this.targetGradation()
      })
    },
    stageEnter(data) {
      this.stageEnterId = data.id
      this.showStageIcon = true
    },
    targetEnter(data) {
      this.showIcon = true
      this.targetEnterId = data.id
    },
    cancel() {
      this.acquire = false
    },
    addKnowledge() {
      let payload = {
        gradationId: this.stageListId,
        knowledgePointIds: this.knowledgeIds
      }
      courseApi.addGradationKnowledge(payload).then(res => {
        this.$message.success('知识点添加成功')
        this.acquire = false
        this.getKnowledge()
      })
    },
    handleCheckChange(data, checkedKeys) {
      let row = []
      checkedKeys.checkedNodes.map(item => {
        row.push(item.id)
      })
      this.knowledgeIds = row
    },
    addAcquire() {
      this.acquire = true
      this.getKnowledgeTree()
    },
    selectMenu(index) {
      index === '1' ? this.getKnowledgeTree() : this.getPlatformKnowledge()
    },
    getKnowledgeTree() {
      this.knowledgeLoading = true
      this.knowledgePointTree = []
      knowledgeApi
        .getOrgKnowledge()
        .then(res => {
          this.knowledgePointTree = res.res
          this.knowledgeLoading = false
        })
        .catch(() => {
          this.knowledgeLoading = false
        })
    },
    getPlatformKnowledge() {
      this.knowledgeLoading = true
      knowledgeApi
        .getPlatformKnowledge()
        .then(res => {
          this.knowledgePointTree = res.res
          this.knowledgeLoading = false
        })
        .catch(() => {
          this.knowledgeLoading = false
        })
    },
    addStageCancel() {
      this.showAddTarget = false
      this.addStage = ''
      this.addStageDescription = ''
    },
    addStageList() {
      if (this.addStageLoading) {
        return
      }
      if (this.addStage === '') {
        this.$message.error('请输入阶段名称')
        return
      }
      if (this.addStage.length > 30) {
        this.$message.error('阶段名称长度不能超过30个字符')
        return
      }
      if (this.addStageDescription.length > 200) {
        this.$message.error('阶段描述长度不能超过200个字符')
        return
      }
      this.addStageLoading = true
      let payload = {
        name: this.addStage,
        targetId: this.targetListId,
        description: this.addStageDescription
      }
      courseApi.addGradation(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('阶段添加成功')
          this.targetGradation()
          this.addStageCancel()
        }
      })
    },
    delStage() {
      if (this.delKnge === true) {
        let payload = {
          gradationId: this.stageListId,
          knowledgePointIds: this.knowledgePointId
        }
        courseApi.deleKnowledge(payload).then(res => {
          this.$message.success('删除成功')
          this.delKnge = false
          this.deleteStage = false
          this.deleteName = ''
          this.getKnowledge()
        })
      } else if (this.deleTarget === true) {
        courseApi.targetBatchDelete(this.delTargetList).then(res => {
          this.delTargetList = []
          this.deleTarget = false
          this.$message.success('目标删除成功')
          this.deleteStage = false
          this.targetListId = 0
          this.getTarget()
        })
      } else if (this.delStages === true) {
        courseApi.delTargetGradation(this.delStageList, this.targetListId).then(res => {
          this.delStages = false
          this.$message.success('阶段删除成功')
          this.stageListId = 0
          this.targetGradation()
          this.deleteStage = false
        })
      }
    },
    showDele(data) {
      this.showDel = true
      this.knowledgeId = data.id
    },
    handleClose(data) {
      this.delKnge = true
      this.knowledgePointId = [data.id]
      this.deleteName = '知识点:' + data.name
      this.$refs.deleteDialog.open()
    },
    getKnowledge() {
      this.rightLoading = true
      courseApi
        .getGradationKnowledge(this.stageListId)
        .then(res => {
          this.knowledgeList = res.res
          let row = []
          res.res.map(item => {
            row.push(item.id)
          })
          this.knowledgePointList = row
        })
        .finally(() => {
          this.rightLoading = false
        })
    },
    getStageList(data) {
      // 关闭知识点弹窗
      this.acquire = false
      this.stageListId = data.id
      this.getKnowledge()
    },
    targetGradation() {
      this.centerLoading = true
      courseApi
        .targetGradation(this.targetListId)
        .then(res => {
          res.res.map(item => {
            if (item.description === undefined) {
              item.description = ''
            }
          })
          this.gradation = res.res.reverse()
          if (res.res[0] !== undefined && this.stageListId === 0) {
            this.stageListId = this.gradation[0].id
          } else if (res.res.length === 0) {
            this.stageListId = 0
          }
          this.getKnowledge()
        })
        .finally(() => {
          this.centerLoading = false
        })
    },
    addCancel() {
      this.addDescription = ''
      this.addTarget = ''
      this.showAdd = false
    },
    addTargetList() {
      if (this.addTargetLoading) {
        return
      }
      if (this.addTarget === '') {
        this.$message.error('请输入目标名称')
        return
      }
      if (this.addTarget.length > 30) {
        this.$message.error('目标名称长度不能超过30个字符')
        return
      }
      if (this.addDescription.length > 200) {
        this.$message.error('目标描述长度不能超过200个字符')
        return
      }
      this.addTargetLoading = true
      let payload = {
        description: this.addDescription,
        name: this.addTarget
      }
      courseApi.targetForm(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('目标添加成功')
          this.targetListId = res.res
          this.getTarget()
          this.addCancel()
        }
      })
    },
    getTargetList(data) {
      this.stageListId = 0
      this.targetListId = data.id
      // 关闭知识点弹窗
      this.acquire = false
      // this.stageListId = 0
      // this.targetListId = []
      this.targetGradation()
    },
    handleAddTarget() {
      this.showAdd = true
    },
    delTarget() {
      if (this.delTargetList.length === 0) {
        this.$message.error('请选择要删除的目标')
      } else {
        this.deleteName = '选中的目标'
        this.$refs.deleteDialog.open()
      }
    },
    toDeleteStage() {
      if (this.delStageList.length === 0) {
        this.$message.error('请选择要删除的阶段')
      } else {
        this.deleteName = '选中的阶段'
        this.$refs.deleteDialog.open()
      }
    },
    clearKnowledge() {
      this.$refs.clearKnowledge.open()
    },
    handleCheck(data) {
      this.deleTarget = true
      this.delTargetList = data
      this.deleteName = '选中的目标'
    },
    getTarget() {
      this.leftLoading = true
      courseApi
        .getTarget()
        .then(res => {
          res.res.map(item => {
            if (item.description === undefined) {
              item.description = ''
            }
          })
          this.targetList = res.res
          if (res.res.length) {
            this.targetListId = res.res[0].id
          } else {
            this.targetListId = 0
          }
        })
        .finally(() => {
          this.leftLoading = false
          this.targetGradation()
        })
    },
    toTargetChart() {
      this.$router.push('/manage/study/target/chart')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/drawer';
.minBody {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 10px;
}
.course-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
}
.cardList {
  height: 100%;
  width: 600px;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  background-color: #ffffff;
}
.top {
  white-space: nowrap;
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #eaedf1;
  line-height: 55px;
  display: flex;
  justify-content: space-between;
}
.tittle {
  width: 30%;
  font-family: Source Han Sans CN;
  font-size: 16px;
  color: #333333;
  display: flex;
  margin-left: 12px;
  align-items: center;
}
.ivu-btn-primary {
  background-color: #4579ea;
}
.target {
  cursor: pointer;
  text-align: initial;
  min-height: 71px;
  border-bottom: 1px solid #eaedf1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  &:hover {
    background-color: #ecf2fc;
  }
}
.description {
  margin: 8px 40px;
  color: #888888;
}
.description-add {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  ::v-deep .ivu-input {
    height: 90px;
  }
}
.knowledge {
  cursor: pointer;
  background: #ecf2fc;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  min-width: 71px;
  margin: 12px 5px;
  color: #4579ea;
  font-size: 14px;
  border-radius: 3px;
}
.knowledgeList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 26px 15px;
}
::v-deep .ivu-drawer-header-inner {
  font-size: 16px;
  font-weight: bold;
}

/*滚动条的宽度*/
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
::-webkit-scrollbar-track {
  width: 6px;
  background-color: #dcdcdc;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  display: none;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.showDetail {
  font-size: 12px;
  color: #4579ea;
  float: right;
}
</style>
